@font-face {
  font-family: FSLola;
  src: url("font.84cca564.woff2") format("woff2"), url("font.2407ade8.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-Bold;
  src: url("font.540fbc59.woff2") format("woff2"), url("font.57ec411e.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-BoldItalic;
  src: url("font.b155388c.woff2") format("woff2"), url("font.9a2ac602.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-ExtraBold;
  src: url("font.1f127e88.woff2") format("woff2"), url("font.3450d318.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-ExtraBoldItalic;
  src: url("font.f7c1ed87.woff2") format("woff2"), url("font.d6874190.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-Bold;
  src: url("font.540fbc59.woff2") format("woff2"), url("font.57ec411e.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-Italic;
  src: url("font.118d3362.woff2") format("woff2"), url("font.18ada9c2.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-Light;
  src: url("font.c330bc08.woff2") format("woff2"), url("font.ca2405a1.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-LightItalic;
  src: url("font.a0ffc56b.woff2") format("woff2"), url("font.eac2c532.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-Medium;
  src: url("font.09b03a08.woff2") format("woff2"), url("font.93fb555f.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: FSLola-MediumItalic;
  src: url("font.1f2886e4.woff2") format("woff2"), url("font.41119429.woff") format("woff");
  font-display: swap;
}

:root {
  --font-fslola-medium: "FSLola-Medium";
  --font-fslola-light: "FSLola-Light";
  --font-fslola-normal: "FSLola";
  --font-fslola-bold: "FSLola-Bold";
  --font-fslola-bold-italic: "FSLola-BoldItalic";
  --font-fslola-italic: "FSLola-Italic";
  --font-size-sm: 10px;
  --font-size-md: 12px;
  --font-size-normal: 16px;
  --font-size-mobile: 18px;
  --font-size-base: 21.58px;
  --font-size-accordion: 20px;
  --font-size-title: 32.375px;
  --font-size-lg: 53px;
  --color-white: #fff;
  --color-indigo: #283583;
}

/*# sourceMappingURL=index.58f649c6.css.map */
